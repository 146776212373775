import Vue from 'vue';
import bugsnag from '@bugsnag/js';
import bugsnagVue from '@bugsnag/plugin-vue';

const bugsnagClient = bugsnag({
  apiKey: process.env.BUGSNAG_API_KEY,
  appVersion: process.env.RELEASE,
  notifyReleaseStages: ['production'],
});

bugsnagClient.use(bugsnagVue, Vue);

Vue.prototype.$bugsnag = bugsnagClient;

export default ({store}, inject) => {
  if (store.state.release) {
    bugsnagClient.app.version = store.state.release;
  }

  inject('bugsnag', bugsnagClient);
};
