import Vue from 'vue';
import {mapState} from 'vuex';

const User = {
  install(Vue) {
    Vue.mixin({
      computed: {
        ...mapState({
          user: state => state.auth.user,
          authenticated: state => state.auth.loggedIn,
        }),
      },
    });
  },
};

Vue.use(User);
